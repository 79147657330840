<template>
  <div
    class="content"
    :class="{ editable: editMode }"
    @click="selectContentBlock()"
    :style="bgStyle()"
    :id="imageTextId"
  >
    <!-- VERSION WITHOUT ITEMS (EXISTING) -->
    <template v-if="!content.items">
      <div class="row imagetext">
        <div :class="textClass()">
          <h1
            v-html="getTranslations(content.title)"
            :class="titleClasses('title')"
            v-if="content.visibilityElements.title"
            :contenteditable="editMode"
            :style="{ color: content.titleColor }"
            @mousedown="setSelectedElement('title')"
            @blur="changeTitle({ element: 'title', value: $event.currentTarget.innerHTML, key: content.title })"
          ></h1>

          <h2
            v-html="getTranslations(content.subtitle)"
            :class="titleClasses('subtitle')"
            v-if="content.visibilityElements.subtitle"
            :contenteditable="editMode"
            :style="{ color: content.subtitleColor }"
            @mousedown="setSelectedElement('subtitle')"
            @blur="changeTitle({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: content.subtitle })"
          ></h2>

          <div
            v-html="getTranslations(content.text)"
            :class="titleClasses('text')"
            v-if="content.visibilityElements.text"
            :contenteditable="editMode"
            @blur="changeTitle({ element: 'text', value: $event.currentTarget.innerHTML, key: content.text })"
            @mousedown="setSelectedElement('text')"
          ></div>
        </div>

        <div :class="mediaClass(content)">
          <content-block-image
            :editMode="editMode"
            :image="getImage(content.backgroundImage)"
            :carousel="getCarousel(item.carousel)"
            :imageUrl="getTranslations(item.imageUrl)"
            :title="getTranslations(content.title)"
            :imageClass="['mt-3', ' mt-md-0', 'mt-lg-0', 'img-fluid']"
          ></content-block-image>
        </div>
      </div>
    </template>

    <!-- VERSION WITH ITEMS -->
    <div
      v-for="(item, i) in content.items"
      :key="i"
      :class="isSelectedItem(i, content)"
      @click="setSelectedElement({ name: 'text', field: 'image', id: i })"
    >
      <div class="row imagetext">
        <div :class="textClassItem(item)">
          <h1
            v-html="getTranslations(item.title)"
            v-if="item.visibilityElements.title"
            :contenteditable="editMode"
            :class="item.alignment"
            :style="{ color: item.titleColor }"
            @click="setSelectedElement({ name: 'text', field: 'title', id: i })"
            @blur="
              handleBlur(
                '[id=\'' + imageTextId + '\'] div:nth-child(' + (i + 1) + ') h1',
                'title',
                item.title,
                false,
                false,
                i
              )
            "
          ></h1>

          <h2
            v-html="getTranslations(item.subtitle)"
            v-if="item.visibilityElements.subtitle"
            :contenteditable="editMode"
            :class="item.alignment"
            :style="{ color: item.subtitleColor }"
            @blur="
              handleBlur(
                '[id=\'' + imageTextId + '\'] div:nth-child(' + (i + 1) + ') h2',
                'subtitle',
                item.subtitle,
                false,
                false,
                i
              )
            "
          ></h2>

          <div
            v-html="getTranslations(item.text)"
            v-if="item.visibilityElements.text"
            :contenteditable="editMode"
            :class="item.alignment"
            @click="setSelectedElement({ name: 'text', field: 'text', id: i })"
            @blur="
              handleBlur(
                '[id=\'' + imageTextId + '\'] div:nth-child(' + (i + 1) + ') .imagetext div > div',
                'text',
                item.text,
                false,
                false,
                i
              )
            "
          ></div>
        </div>

        <div :class="mediaClass(content)">
          <template v-if="item.visibilityElements.image">
            <div :class="mediaPosition(item)">
              <content-block-image
                :editMode="editMode"
                :image="getImage(item.image, content.variant === 1 ? true : false)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :title="getTranslations(item.title)"
                :imageClass="['mt-3', ' mt-md-0', 'mt-lg-0', 'img-fluid']"
              ></content-block-image>
            </div>
          </template>

          <template v-if="item.visibilityElements.video">
            <video-block
              :class="mediaPosition(item)"
              :pageId="pageId"
              :content="content"
              :editMode="editMode"
              :url="getTranslations(item.video)"
            ></video-block>
          </template>

          <template v-if="item.visibilityElements.iframe">
            <iframe-block :class="mediaPosition(item)" :source="item.iframe"></iframe-block>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import IframeBlock from '@lp/components/content-blocks/IframeBlock.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
export default {
  extends: BaseContentComponent,
  components: {
    VideoBlock,
    IframeBlock
  },
  props: {},
  data() {
    return {
      imageTextId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.imageTextId}"] div:nth-child(${index + 1}) h1`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.imageTextId}"] div:nth-child(${index + 1}) h2`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.imageTextId}"] div:nth-child(${index + 1}) .imagetext div > div`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.imageTextId}"] div:nth-child(${i + 1}) h1`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.imageTextId}"] div:nth-child(${i + 1}) h2`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.text && !oldContent.items[i].visibilityElements.text) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.imageTextId}"] div:nth-child(${i + 1}) .imagetext div > div`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new image text component
        setTimeout(() => {
          mediumEditor(
            `[id="${this.imageTextId}"] div:nth-child(${newContent.items.length}) h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.imageTextId}"] div:nth-child(${newContent.items.length}) h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.imageTextId}"] div:nth-child(${newContent.items.length}) .imagetext div > div`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    bgStyle() {
      let style = {};
      style = { 'background-color': this.content.backgroundColor };
      return style;
    },
    textClass() {
      let ret = [];
      if (this.content.alignmentElements.image === 'left') {
        ret.push('order-2');
      }
      if (this.content.variant === 0) {
        ret.push('col-md-6');
      } else {
        ret.push('col-md-8');
      }

      return ret;
    },
    textClassItem(item) {
      let ret = [];
      if (item.imagePos.includes('left')) {
        ret.push('order-2');
      }
      if (item.visibilityElements.image || item.visibilityElements.video || item.visibilityElements.iframe) {
        if (this.content.variant === 0) {
          ret.push('col-md-6');
        } else {
          ret.push('col-md-7');
        }
      } else {
        ret.push('col-md-12');
      }

      return ret;
    },
    mediaClass() {
      if (this.content.variant === 0) {
        return ['col-md-6'];
      } else {
        return ['col-md-5'];
      }
    },
    mediaPosition(item) {
      return item.imagePos;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

.image {
  padding: 0;
}
.imagetext {
  padding: 5px 0 0 0;
}

.content {
  border-radius: $general-border-radius;
}

.content-block.selected .content {
  border-radius: 0;
}

.middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.bottom {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}
</style>
