<template>
  <div
    class="content content-tabs"
    :id="content.type + '-' + content.id"
    :class="{ editable: editMode }"
    @click="selectContentBlock()"
  >
    <el-tabs type="card" @tab-click="handleTabClick" :tab-position="tabPosition()">
      <el-tab-pane v-for="(item, i) in content.items" :key="i" :style="{ 'background-color': item.backgroundColor }">
        <template slot="label">
          <div
            :contenteditable="editMode"
            @paste="pasteOnTableTitle"
            v-html="getTranslations(item.tabTitle)"
            @blur="changeItems({ element: 'tabTitle', value: $event.currentTarget.innerHTML, key: item.tabTitle })"
          ></div>
        </template>
        <div class="row">
          <div
            class="col-sm-12"
            v-if="item.imagePos !== 'right'"
            :class="{ 'col-md-5': item.imagePos === 'left' }"
            @click="setSelectedElement({ name: 'tab', field: 'image', id: i })"
          >
            <content-block-image
              v-if="item.visibilityElements.image"
              :editMode="editMode"
              :image="getImage(item.image)"
              :carousel="getCarousel(item.carousel)"
              :imageUrl="getTranslations(item.imageUrl)"
              :title="getTranslations(item.title)"
            ></content-block-image>

            <div v-if="item.visibilityElements.video">
              <video-block
                :pageId="pageId"
                :content="content"
                :editMode="editMode"
                :url="getTranslations(item.video)"
              ></video-block>
            </div>

            <div v-if="item.visibilityElements.iframe">
              <iframe-block :source="item.iframe"></iframe-block>
            </div>
          </div>
          <div
            class="col-sm-12"
            :class="{
              'col-md-7':
                item.visibilityElements.image ||
                item.visibilityElements.video ||
                (item.visibilityElements.iframe && (item.imagePos === 'left' || item.imagePos === 'right')),
              'col-md-12': item.imagePos === 'center',
              'mt-3': item.imagePos === 'center'
            }"
          >
            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              class="mt-3"
              :class="{
                'mt-md-0 mt-lg-0': (!content.tabsVariant || !item.visibilityElements.image) && item.imagePos !== 'top'
              }"
              @mousedown="setSelectedElement({ name: 'tab', field: 'title', id: i })"
              :style="{ color: item.titleColor }"
              v-if="item.visibilityElements.title"
              @blur="
                handleBlur(
                  '[id=\'' + content.type + '-' + content.id + '\'] [role=\'tabpanel\']:nth-child(' + (i + 1) + ') h3',
                  'title',
                  item.title,
                  false,
                  false,
                  i
                )
              "
            ></h3>

            <h6
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              @mousedown="setSelectedElement({ name: 'tab', field: 'subtitle', id: i })"
              :style="{ color: item.subtitleColor }"
              v-if="item.visibilityElements.subtitle"
              @blur="
                handleBlur(
                  '[id=\'' + content.type + '-' + content.id + '\'] [role=\'tabpanel\']:nth-child(' + (i + 1) + ') h6',
                  'subtitle',
                  item.subtitle,
                  false,
                  false,
                  i
                )
              "
            ></h6>

            <div
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              class="text-element-content-in-tab"
              @mousedown="setSelectedElement({ name: 'tab', field: 'text', id: i })"
              @blur="
                handleBlur(
                  '[id=\'' +
                    content.type +
                    '-' +
                    content.id +
                    '\'] [role=\'tabpanel\']:nth-child(' +
                    (i + 1) +
                    ') .text-element-content-in-tab',
                  'text',
                  item.text,
                  false,
                  false,
                  i
                )
              "
            ></div>
          </div>
          <div
            v-if="item.imagePos === 'right' || item.imagePos === 'bottom'"
            class="col-sm-12"
            :class="{
              'col-md-5': item.imagePos === 'right',
              'mt-3': item.imagePos === 'bottom',
              'mb-3': item.imagePos === 'center'
            }"
            @click="setSelectedElement({ name: 'tab', field: 'image', id: i })"
          >
            <content-block-image
              v-if="item.visibilityElements.image"
              :editMode="editMode"
              :image="getImage(item.image)"
              :carousel="getCarousel(item.carousel)"
              :imageUrl="getTranslations(item.imageUrl)"
              :title="getTranslations(item.title)"
              class="something"
            ></content-block-image>

            <div v-if="item.visibilityElements.video">
              <video-block
                :pageId="pageId"
                :content="content"
                :editMode="editMode"
                :url="getTranslations(item.video)"
              ></video-block>
            </div>

            <div v-if="item.visibilityElements.iframe">
              <iframe-block :source="item.iframe"></iframe-block>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Tabs, TabPane } from 'element-ui';
import BaseContentComponent from './BaseContentComponent.vue';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import IframeBlock from '@lp/components/content-blocks/IframeBlock.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
export default {
  extends: BaseContentComponent,
  components: {
    elTabs: Tabs,
    elTabPane: TabPane,
    VideoBlock,
    IframeBlock
  },
  mounted() {
    if (this.editMode) {
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index + 1}) h3`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index + 1}) h6`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index +
            1}) .text-element-content-in-tab`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
    this.setTabColors();
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${i + 1}) h3`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${i + 1}) h6`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new accordion component
        setTimeout(() => {
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${newContent.items.length}) h3`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${newContent.items.length}) h6`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${newContent.items.length}) .text-element-content-in-tab`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }

      let index = newContent.items.findIndex((item, i) => {
        return item.imagePos !== oldContent.items[i].imagePos;
      });
      if (index !== -1) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index + 1}) h3`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index + 1}) h6`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [role="tabpanel"]:nth-child(${index +
              1}) .text-element-content-in-tab`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      this.setTabColors();
    }
  },
  methods: {
    pasteOnTableTitle(event) {
      event.preventDefault();
      var text = event.clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
    },
    handleTabClick(tab, event) {
      if (this.editMode) {
        this.setSelectedElement({ name: 'tab', field: 'title', id: parseInt(tab.index) });
      }
      this.setTabColors();
    },
    tabPosition() {
      switch (this.content.tabsVariant) {
        case 0:
          return 'top';
        case 1:
          return 'left';
      }
    },
    setTabColors() {
      setTimeout(() => {
        const el = document
          .getElementById(`${this.content.type}-${this.content.id}`)
          .querySelectorAll('.el-tabs__item');
        if (el.length) {
          el.forEach((e) => {
            e.style.backgroundColor = this.content.tabColor;
            e.style.borderColor = this.content.tabColor === '#FFFFFF' ? '#e5e5e5' : this.content.tabColor;
            e.style.color = this.content.tabTextColor;
          });
        }
        const elActive = document
          .getElementById(`${this.content.type}-${this.content.id}`)
          .querySelectorAll('.el-tabs__item.is-active');
        if (elActive.length) {
          elActive.forEach((e) => {
            e.style.backgroundColor = this.content.activeTabColor;
            e.style.borderColor = this.content.activeTabColor === '#FFFFFF' ? '#e5e5e5' : this.content.activeTabColor;
            e.style.color = this.content.activeTabTextColor;
          });
        }
      }, 50);
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

.screen.phone {
  .el-tabs__header.is-left {
    float: none;
    margin-bottom: 30px;
  }
  .el-tabs__nav.is-left {
    width: 100%;
  }
}

.screen.tablet {
  .el-tab-pane .col-md-7 {
    margin-top: 0 !important;
  }
}

.content-tabs {
  .el-tabs--card {
    > .el-tabs__header {
      border: none;
      margin: 0 0 30px;
      z-index: 0;

      &.is-left {
        @media (max-width: 767px) {
          float: none;
          margin-bottom: 30px;
          .el-tabs__nav.is-left {
            width: 100%;
          }
        }
      }

      .el-tabs__nav {
        border-radius: $general-border-radius;
        border: none !important;
      }
    }

    .is-scrollable {
      .el-tabs__nav-scroll {
        display: block;
      }
    }

    .el-tabs__nav-scroll {
      justify-content: center;
      align-items: center;
      display: flex;

      &.is-left {
        border-radius: $general-border-radius;
      }

      .el-tabs__nav {
        .el-tabs__item {
          padding: 0 3rem;
          font-weight: boldStyle;
          width: auto !important;
          border-style: solid;
          border-width: 1px;

          &.is-active {
            > div {
              height: 100%;
              min-width: 40px;
            }
          }
          > div {
            text-align: center;
          }

          &:not(.is-left) {
            &:first-child {
              border-bottom-left-radius: $general-border-radius;
              border-top-left-radius: $general-border-radius;
            }
            &:last-child {
              border-bottom-right-radius: $general-border-radius;
              border-top-right-radius: $general-border-radius;
            }
          }
          &.is-left {
            &:first-child {
              border-radius: 0;
              border-width: 1px;
              border-style: solid;
              border-top-left-radius: $general-border-radius;
              border-top-right-radius: $general-border-radius;
            }
            &:last-child {
              border-radius: 0;
              border-bottom-left-radius: $general-border-radius;
              border-bottom-right-radius: $general-border-radius;
            }
          }
        }
      }
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: $general-border-radius;
  }
  h6 {
    padding-top: 10px;
    margin-bottom: 0;
  }
}
</style>
