<template>
  <div class="content-block-header" :class="headerVariantClass()" :id="textId">
    <div class="content" :class="{ editable: editMode }" :style="bgStyle()" @click="selectContentBlock()">
      <!-- VERSION WITHOUT ITEMS (EXISTING) -->
      <template v-if="!content.items">
        <h1
          v-html="getTranslations(content.title)"
          :class="titleClasses('title')"
          :style="{ color: content.titleColor }"
          v-if="content.visibilityElements.title"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('title')"
          @blur="
            handleBlur(
              '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') h1',
              'title',
              item.title,
              false,
              false,
              i
            )
          "
        ></h1>

        <h2
          v-html="getTranslations(content.subtitle)"
          :class="titleClasses('subtitle')"
          :style="{ color: content.subtitleColor }"
          v-if="content.visibilityElements.subtitle"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('subtitle')"
          @blur="
            handleBlur(
              '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') h2',
              'subtitle',
              item.subtitle,
              false,
              false,
              i
            )
          "
        ></h2>

        <div
          v-html="getTranslations(content.text)"
          :class="titleClasses('text')"
          v-if="content.visibilityElements.text"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('text')"
          @blur="
            handleBlur(
              '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') > div',
              'text',
              item.text,
              false,
              false,
              i
            )
          "
        ></div>
      </template>

      <!-- VERSION WITH ITEMS -->
      <template v-if="content.items">
        <div
          v-for="(item, i) in content.items"
          :key="i"
          :class="isSelectedItem(i, content)"
          class="item-element"
          @click="setSelectedElement({ name: 'text', id: i })"
        >
          <h1
            v-html="getTranslations(item.title)"
            v-if="item.visibilityElements.title"
            :contenteditable="editMode"
            :class="item.alignment"
            :style="{ color: item.titleColor }"
            @click="setSelectedElement({ name: 'text', field: 'title', id: i })"
            @blur="
              handleBlur(
                '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') h1',
                'title',
                item.title,
                false,
                false,
                i
              )
            "
          ></h1>

          <h2
            v-html="getTranslations(item.subtitle)"
            v-if="item.visibilityElements.subtitle"
            :contenteditable="editMode"
            :class="item.alignment"
            :style="{ color: item.subtitleColor }"
            @click="setSelectedElement({ name: 'text', field: 'subtitle', id: i })"
            @blur="
              handleBlur(
                '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') h2',
                'subtitle',
                item.subtitle,
                false,
                false,
                i
              )
            "
          ></h2>

          <div
            v-html="getTranslations(item.text)"
            v-if="item.visibilityElements.text"
            :contenteditable="editMode"
            :class="item.alignment"
            @click="setSelectedElement({ name: 'text', field: 'text', id: i })"
            @blur="
              handleBlur(
                '[id=\'' + textId + '\'] .content > div:nth-child(' + (i + 1) + ') > div',
                'text',
                item.text,
                false,
                false,
                i
              )
            "
          ></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
export default {
  extends: BaseContentComponent,
  props: {},
  data() {
    return {
      textId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.textId}"] .content > div:nth-child(${index + 1}) h1`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.textId}"] .content > div:nth-child(${index + 1}) h2`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.textId}"] .content > div:nth-child(${index + 1}) > div`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.textId}"] .content > div:nth-child(${i + 1}) h1`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.textId}"] .content > div:nth-child(${i + 1}) h2`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.text && !oldContent.items[i].visibilityElements.text) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.textId}"] .content > div:nth-child(${i + 1}) > div`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new accordion componentç
        setTimeout(() => {
          mediumEditor(
            `[id="${this.textId}"] .content > div:nth-child(${newContent.items.length}) h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.textId}"] .content > div:nth-child(${newContent.items.length}) h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.textId}"] .content > div:nth-child(${newContent.items.length}) > div`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    headerVariantClass() {
      return `variant-${this.content.headerVariant}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_helpers.scss';

.content {
  border-radius: $general-border-radius;
}

.item-element {
  padding: 5px 0 0 0;
}

.content-block.selected .content {
  border-radius: 0;
}
</style>
