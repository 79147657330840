<template>
  <div
    :id="'form-' + content.formId"
    class="content form-content-block"
    v-if="showForm"
    :class="{ editable: editMode }"
    @click="selectContentBlock()"
  >
    <div class="text-center" v-if="loading">
      <loader />
    </div>
    <div v-else>
      <div v-if="isInAdmin">
        <h1 v-if="!isInTreeView && formInfo">{{ formInfo.title }}</h1>
        <button type="button" @click="goToFormEditor">
          {{ 'forms.goToFormEditor' | translate }}<i class="material-icons">edit</i>
        </button>
      </div>
      <div v-if="!loading && !isInAdmin && form && allowSubmit">
        <template v-if="showAsLink">
          <h1>{{ form.title }}</h1>
          <button :disabled="isInPreview" @click="openForm()" :style="buttonStyle(content.displayOptions)">
            {{ content.displayOptions.buttonText }}
          </button>
        </template>
        <template v-else-if="isInPreview">
          <form-preview @exitPreview="exitPreview"></form-preview>
        </template>
        <template v-else>
          <form-preview :form="form"></form-preview>
        </template>
      </div>
      <div v-if="!allowSubmit && form" class="formFinished">
        <h1>{{ form.title }}</h1>
        <h2>{{ 'forms.notAllowedToSubmit' | translate }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueFormJsonSchema from 'vue-form-json-schema';
import FormPreview from '@forms/components/FormPreview.vue';

import BaseContentComponent from './BaseContentComponent.vue';
import buildUISchema from '@forms/mixins/uischema.js';

export default {
  extends: BaseContentComponent,
  components: {
    VueFormJsonSchema,
    FormPreview
  },
  created() {
    // Load for onboardee
    if (!this.isInAdmin && !this.isInPreview) {
      this.loading = true;
      const user = JSON.parse(window.localStorage.user);

      Vue.prototype.$http
        .get(
          `/dashboard/v2/forms/${this.$route.params.module}/${this.$route.params.id}/${this.content.formId}?languageId=${user.languageId}`
        )
        .then(
          (response) => {
            if (response.data.message && response.data.message === 'Published form version was not found') {
              return;
            }

            // No need to load the form its not allowed to resubmit
            if (response.data.formSubmit && !response.data.editAfterSubmission) {
              this.allowSubmit = false;
            }

            this.form = response.data;
            this.formSchema = this.form.versions[this.form.versions.length - 1].schema;

            if (this.formSchema.properties) {
              this.showForm = true;
            }

            this.checkScrollToForm();
            this.loading = false;
          },
          () => {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.errorGettingForm'),
              position: 'bottom-right'
            });
            this.loading = false;
          }
        );
    } else if (this.isInPreview) {
      // Load for preview in LP
      this.showForm = true;
      this.getForm();
    } else if (this.isInAdmin) {
      this.showForm = true;
    }
  },
  methods: {
    checkScrollToForm() {
      const formId = this.$route.params.formId;
      if (formId) {
        setTimeout(() => {
          const formDiv = document.getElementById(`form-${formId}`);
          if (formDiv) {
            window.scrollTo(0, formDiv.getBoundingClientRect().top - 100);
          }
        }, 1);
      }
    },
    openForm() {
      if (!this.isInPreview) {
        this.$router.push({
          name: 'form',
          params: {
            moduleId: this.$route.params.module,
            learningpageId: this.$route.params.id,
            formId: this.content.formId
          }
        });
      }
    },
    goToFormEditor() {
      const lpId = this.$route.params.id;

      this.$store.dispatch('forms/getFormById', { formId: this.content.formId }).then(
        (form) => {
          this.$router.push({ name: 'FormEditorById', params: { id: this.content.formId } }, () => {
            window.localStorage.redirectFromLearningPage = lpId;
          });

          const changes = this.hasChanges(lpId);
          if (!changes) {
            this.$router.go();
          } else {
            this.$store.commit('editor/forceRefresh', true);
          }
        },
        (error) => {
          // Detach form from LP on removal
          if (error.response.data.error === 'Form was not found') {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.errorFormRemovedWhileAttachedToLP', { formId: this.content.formId }),
              position: 'bottom-right'
            });

            this.$store.commit('learningPages/removeFormIdFromLearningPage', Number(this.content.formId));
            this.$store.commit('formBuilder/removeFilterWithFormId', Number(this.content.formId));
            this.$store.commit('learningPages/removeContentBlock', this.content.id);
            const lpWithoutForm = this.getLearningPageById(this.pageData.id);
            const initTranslations = this.getInitialTranslationsById(this.pageData.id);
            this.$store.dispatch('learningPages/updateLearningPage', { learningPage: lpWithoutForm, initTranslations });
          }
        }
      );
    },
    buttonStyle(displayOptions) {
      let style = {
        backgroundColor: displayOptions.buttonBackgroundColor,
        color: displayOptions.buttonTextColor
      };
      return style;
    },
    getForm() {
      const formId = this.content.formId;
      this.$store
        .dispatch('forms/getFormById', { formId: formId, store: true })
        .then((form) => {
          this.setFormToStore(form.id);
          this.loading = false;
        })
        .catch((error) => this.showErrorNotification(error, this.$t('notifications.errorGettingForm')));
    },
    setFormToStore(formId) {
      this.form = this.formById(formId);
      this.$store.commit('formBuilder/setForm', this.form);
    },
    exitPreview() {
      if (this.inViewMode) {
        this.$router.go(-1);
      } else {
        this.$router.push(`/learning-pages/editor/${this.$route.params.id}`);
      }
    }
  },
  data() {
    return {
      model: {},
      uiSchema: [],
      schema: {},
      loading: false,
      form: null,
      allowSubmit: true,
      showForm: false
    };
  },
  computed: {
    isInAdmin() {
      return (
        this.$route.name === 'EditorById' ||
        this.$route.name === 'EditorByModuleId' ||
        this.$route.name === 'TreeView' ||
        this.$route.name === 'Library'
      );
    },
    isInPreview() {
      return this.$route.name === 'Preview';
    },
    isInTreeView() {
      return this.$route.name === 'TreeView';
    },
    formInfo() {
      return this.formById(this.content.formId);
    },
    showAsLink() {
      return this.content.displayOptions.showAsLink;
    },
    ...mapGetters('forms', ['formById']),
    ...mapGetters('learningPages', ['hasChanges', 'getInitialTranslationsById']),
    ...mapGetters('settings', ['featureFormEnabled']),
    ...mapGetters('editor', ['inViewMode'])
  }
};
</script>

<style lang="scss" scoped>
.image-content-block {
  img {
    width: 100%;
  }
}

button {
  font-size: 1.2em;
  border-radius: 4px;
  text-decoration: none !important;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #666666;
  border: 1px solid #dddddd;
  padding: 8px;
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer;

  .material-icons {
    vertical-align: bottom;
    margin-left: 8px;
  }
}

button:hover {
  background-color: #f9f9f9;
}

.formFinished {
  text-align: center;
}
</style>
