var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-block-header",class:_vm.headerVariantClass(),attrs:{"id":_vm.textId}},[_c('div',{staticClass:"content",class:{ editable: _vm.editMode },style:(_vm.bgStyle()),on:{"click":function($event){return _vm.selectContentBlock()}}},[(!_vm.content.items)?[(_vm.content.visibilityElements.title)?_c('h1',{class:_vm.titleClasses('title'),style:({ color: _vm.content.titleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(_vm.content.title))},on:{"mousedown":function($event){return _vm.setSelectedElement('title')},"blur":function($event){_vm.handleBlur(
            '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (_vm.i + 1) + ') h1',
            'title',
            _vm.item.title,
            false,
            false,
            _vm.i
          )}}}):_vm._e(),(_vm.content.visibilityElements.subtitle)?_c('h2',{class:_vm.titleClasses('subtitle'),style:({ color: _vm.content.subtitleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(_vm.content.subtitle))},on:{"mousedown":function($event){return _vm.setSelectedElement('subtitle')},"blur":function($event){_vm.handleBlur(
            '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (_vm.i + 1) + ') h2',
            'subtitle',
            _vm.item.subtitle,
            false,
            false,
            _vm.i
          )}}}):_vm._e(),(_vm.content.visibilityElements.text)?_c('div',{class:_vm.titleClasses('text'),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(_vm.content.text))},on:{"mousedown":function($event){return _vm.setSelectedElement('text')},"blur":function($event){_vm.handleBlur(
            '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (_vm.i + 1) + ') > div',
            'text',
            _vm.item.text,
            false,
            false,
            _vm.i
          )}}}):_vm._e()]:_vm._e(),(_vm.content.items)?_vm._l((_vm.content.items),function(item,i){return _c('div',{key:i,staticClass:"item-element",class:_vm.isSelectedItem(i, _vm.content),on:{"click":function($event){return _vm.setSelectedElement({ name: 'text', id: i })}}},[(item.visibilityElements.title)?_c('h1',{class:item.alignment,style:({ color: item.titleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.title))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'text', field: 'title', id: i })},"blur":function($event){_vm.handleBlur(
              '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (i + 1) + ') h1',
              'title',
              item.title,
              false,
              false,
              i
            )}}}):_vm._e(),(item.visibilityElements.subtitle)?_c('h2',{class:item.alignment,style:({ color: item.subtitleColor }),attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.subtitle))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'text', field: 'subtitle', id: i })},"blur":function($event){_vm.handleBlur(
              '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (i + 1) + ') h2',
              'subtitle',
              item.subtitle,
              false,
              false,
              i
            )}}}):_vm._e(),(item.visibilityElements.text)?_c('div',{class:item.alignment,attrs:{"contenteditable":_vm.editMode},domProps:{"innerHTML":_vm._s(_vm.getTranslations(item.text))},on:{"click":function($event){return _vm.setSelectedElement({ name: 'text', field: 'text', id: i })},"blur":function($event){_vm.handleBlur(
              '[id=\'' + _vm.textId + '\'] .content > div:nth-child(' + (i + 1) + ') > div',
              'text',
              item.text,
              false,
              false,
              i
            )}}}):_vm._e()])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }