<template>
  <div class="secure-files-content-block" :id="secureFileId">
    <div class="content" :class="{ editable: editMode }" :style="bgStyle()" @click="selectContentBlock()">
      <h1
        v-html="getTranslations(content.title)"
        :class="titleClasses('title')"
        v-if="content.visibilityElements.title"
        :contenteditable="editMode"
        :style="{ color: content.titleColor }"
        @mousedown="setSelectedElement('title')"
        @blur="handleBlur('[id=\'' + secureFileId + '\'] .content h1', 'title', content.title, true, true)"
      ></h1>
      <h2
        v-html="getTranslations(content.subtitle)"
        :class="titleClasses('subtitle')"
        v-if="content.visibilityElements.subtitle"
        :contenteditable="editMode"
        :style="{ color: content.subtitleColor }"
        @mousedown="setSelectedElement('subtitle')"
        @blur="handleBlur('[id=\'' + secureFileId + '\'] .content h2', 'subtitle', content.subtitle, true, true)"
      ></h2>
      <template v-if="loading"> <loader /></template>
      <template v-else>
        <template v-if="content.visibilityElements.showAsTable">
          <el-table
            ref="taskTable"
            :data="companyFiles"
            :default-sort="{ prop: 'title', order: 'descending' }"
            stripe
            style="width: 100%"
            :empty-text="$t('task.attachedFiles.emptyTable')"
          >
            <el-table-column :label="$t('task.table.attachedFileName')" prop="title" min-width="25" sortable>
              <template slot-scope="scope">
                <a href="#" class="align-bottom" target="_blank" @click="getFile($event, scope.row.id, scope.row)">
                  {{ scope.row.name || scope.row.fileName || 'Unnamed file' }}
                </a>
              </template>
            </el-table-column>
            <el-table-column
              v-if="content.visibilityElements.description"
              :label="$t('labels.settingDescription')"
              prop="description"
              min-width="25"
              sortable
            >
              <template slot-scope="scope">
                {{ scope.row.description }}
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <template v-if="companyFiles.length === 0">
            {{ $t('task.attachedFiles.emptyTable') }}
          </template>
          <template v-else>
            <ul>
              <li v-for="(file, index) in companyFiles" :key="index" :style="{ color: content.textColor }">
                <a href="#" class="align-bottom" target="_blank" @click="getFile($event, file.id, file)">
                  {{ file.name || file.fileName || 'Unnamed file' }}
                </a>
                <template v-if="file.description && content.visibilityElements.description"> - </template>
                <span v-if="content.visibilityElements.description"> {{ file.description }}</span>
              </li>
            </ul>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import orderBy from 'lodash/orderBy';
var FileSaver = require('file-saver');

export default {
  extends: BaseContentComponent,
  data() {
    return {
      secureFileId: uuid.v4(),
      companyFiles: [],
      loading: false
    };
  },
  created() {
    this.getCompanyFiles();
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.secureFileId}"] .content h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.secureFileId}"] .content h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  computed: {
    ...mapGetters('learningPages', ['localeCode', 'defaultLocaleCode']),
    previewMode() {
      return window.location.href.indexOf('preview') >= 0 || window.location.href.indexOf('library') >= 0;
    }
  },
  methods: {
    getFile(e, companyFileId, file) {
      e.preventDefault();
      e.stopPropagation();
      let responsePromise;
      if (this.editMode || this.previewMode) {
        responsePromise = this.$store.dispatch('learningPages/getCompanyFileAdminData', companyFileId);
      } else {
        //  Onboardee
        responsePromise = this.$store.dispatch('learningPages/getCompanyFileOnboardeeData', companyFileId);
      }

      const formatToExtensionDict = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        'text/plain': 'txt',
        'text/xml': 'xml'
      };

      responsePromise
        .then((file) => file.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const extension = formatToExtensionDict[file.contentType];
          if (extension !== undefined && extension !== null) {
            const expectedFileName = file.name != undefined ? file.name : file.fileName;
            const exportFileName = expectedFileName.endsWith(extension)
              ? expectedFileName
              : `${expectedFileName}.${extension}`;
            FileSaver.saveAs(blob, exportFileName);
          } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name || file.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        });
    },
    getCompanyFiles() {
      this.loading = true;
      this.companyFiles = [];
      let filesToFetch = this.content.companyFileIds[this.localeCode];
      if (!filesToFetch) {
        filesToFetch = this.content.companyFileIds[this.defaultLocaleCode];
      }

      if (!filesToFetch) {
        this.loading = false;
        return;
      }

      let filesFetched = 0;
      let tempCompanyFiles = [];
      // Admin
      if (this.editMode || this.previewMode) {
        filesToFetch.forEach((companyFileId) => {
          this.$store
            .dispatch('learningPages/getCompanyFileAdmin', companyFileId)
            .then((companyFile) => {
              if (!companyFile.name) {
                companyFile.name = '';
              }

              if (!companyFile.url) {
                companyFile.url = '';
              }
              tempCompanyFiles.push(companyFile);
            })
            .finally(() => {
              filesFetched++;
              if (filesFetched == filesToFetch.length) {
                this.companyFiles = orderBy(
                  tempCompanyFiles,
                  [(file) => file.name.toLowerCase(), (file) => file.url.toLowerCase()],
                  ['desc', 'desc']
                );
                this.loading = false;
              }
            });
        });
      } else {
        // Onboardee app
        filesToFetch.forEach((companyFileId) => {
          this.$store
            .dispatch('learningPages/getCompanyFileOnboardee', companyFileId)
            .then((companyFile) => {
              if (!companyFile.fileName) {
                companyFile.fileName = '';
              }

              if (!companyFile.url) {
                companyFile.url = '';
              }
              tempCompanyFiles.push(companyFile);
            })
            .finally(() => {
              filesFetched++;
              if (filesFetched == filesToFetch.length) {
                this.companyFiles = orderBy(
                  tempCompanyFiles,
                  [(file) => file.fileName.toLowerCase(), (file) => file.url.toLowerCase()],
                  ['desc', 'desc']
                );
                this.loading = false;
              }
            });
        });
      }
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.secureFileId}"].content h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.secureFileId}"].content h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }

      if (!isEqual(newContent.companyFileIds, oldContent.companyFileIds)) {
        this.getCompanyFiles();
      }
    },
    localeCode(newLocale, oldLocale) {
      if (!isEqual(newLocale, oldLocale)) {
        this.getCompanyFiles();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  max-width: 95%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-bottom {
  vertical-align: bottom;
}

ul {
  list-style: none;
  margin: 0;
}

ul li::before {
  content: '\2022';
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
