<template>
  <div>
    <div v-if="isGoogleMapCode">
      <div class="map" v-html="getMapSource" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: String
    },
    mapHeight: {
      type: Number,
      default: 300
    }
  },
  computed: {
    getMapSource() {
      if (!this.isGoogleMapCode) {
        return;
      }

      return `<iframe src="${this.source}" width="100%" height="${this.mapHeight}" frameborder="0" style="border:0" allowfullscreen></iframe>`;
    },
    isGoogleMapCode() {
      if (!this.source) {
        return false;
      }
      return this.source.includes('www.google.com/maps');
    }
  }
};
</script>
