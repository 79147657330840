<template>
  <div :id="content.type + '-' + content.id">
    <div class="content timeline-content-block" :class="{ editable: editMode }" @click="selectContentBlock()">
      <div class="titles">
        <h1
          v-html="getTranslations(content.title)"
          :class="titleClasses('title')"
          v-if="content.visibilityElements.title"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('title')"
          :style="{ color: content.titleColor }"
          @blur="
            handleBlur(
              '[id=\'' + content.type + '-' + content.id + '\'] .content .titles h1',
              'title',
              content.title,
              false,
              true
            )
          "
        ></h1>

        <h2
          v-html="getTranslations(content.subtitle)"
          :class="titleClasses('subtitle')"
          v-if="content.visibilityElements.subtitle"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('subtitle')"
          :style="{ color: content.subtitleColor }"
          @blur="
            handleBlur(
              '[id=\'' + content.type + '-' + content.id + '\'] .content .titles h2',
              'subtitle',
              content.subtitle,
              false,
              true
            )
          "
        ></h2>
      </div>
      <div class="mt-5" id="timeline">
        <div v-for="(item, i) in content.items" :key="i" class="row timeline-item" :class="{ active: isActive(item) }">
          <div class="col-lg-6" :class="{ 'empty-card-left order-1': i % 2, 'empty-card-right order-2': !(i % 2) }">
            &nbsp;
          </div>

          <div
            class="col-lg-6"
            :class="{
              'timeline-card-right order-2': i % 2,
              'timeline-card-left order-1': !(i % 2),
              'first-without-image': !item.visibilityElements.image && i === 0,
              'without-image': !item.visibilityElements.image
            }"
            @click="setSelectedElement({ name: 'tl', field: 'title', id: i })"
          >
            <div
              class="card timeline-content"
              :class="{ selected: contentSelected(i, content), 'margin-without-image': !item.visibilityElements.image }"
              :style="{
                backgroundColor: item.backgroundColor
              }"
            >
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :title="getTranslations(item.title)"
                :image="getImage(item.image)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                class="w-100 card-img-top"
              ></content-block-image>
              <div
                class="card-body"
                :class="item.alignment"
                style="border-top: none"
                :style="{
                  border:
                    '1px solid ' + (item.backgroundColor === '#FFFFFF' || '#FFFEFE' ? '#e9e9e9' : item.backgroundColor)
                }"
              >
                <h4
                  :contenteditable="editMode"
                  v-html="getTranslations(item.title)"
                  @click="setSelectedElement({ name: 'tl', field: 'title', id: i })"
                  :style="{ color: item.titleColor }"
                  v-if="item.visibilityElements.title"
                  @blur="
                    handleBlur(
                      '[id=\'' +
                        content.type +
                        '-' +
                        content.id +
                        '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                        (i + 1) +
                        ') h4',
                      'title',
                      item.title,
                      false,
                      false,
                      i
                    )
                  "
                ></h4>
                <h5
                  :contenteditable="editMode"
                  v-html="getTranslations(item.subtitle)"
                  @click="setSelectedElement({ name: 'tl', field: 'subtitle', id: i })"
                  :style="{ color: item.subtitleColor }"
                  v-if="item.visibilityElements.subtitle"
                  @blur="
                    handleBlur(
                      '[id=\'' +
                        content.type +
                        '-' +
                        content.id +
                        '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                        (i + 1) +
                        ') h5',
                      'subtitle',
                      item.subtitle,
                      false,
                      false,
                      i
                    )
                  "
                ></h5>
                <div
                  :contenteditable="editMode"
                  v-html="getTranslations(item.text)"
                  class="content-box"
                  @click="setSelectedElement({ name: 'tl', field: 'text', id: i })"
                  @blur="
                    handleBlur(
                      '[id=\'' +
                        content.type +
                        '-' +
                        content.id +
                        '\'] [id=\'timeline\'] .timeline-item:nth-child(' +
                        (i + 1) +
                        ') .card-body > div',
                      'text',
                      item.text,
                      false,
                      false,
                      i
                    )
                  "
                ></div>
                <el-button
                  v-if="item.visibilityElements.button"
                  type="info"
                  :disabled="!isActive(item) && !isInPreviewAdmin()"
                  size="medium"
                  @click="
                    goToURL(item);
                    setSelectedElement({ name: 'tl', field: 'buttonText', id: i });
                  "
                  :style="buttonStyle(item)"
                >
                  <span
                    :contenteditable="editMode"
                    @paste="setPlainText"
                    @blur="
                      changeItems({
                        element: 'buttonText',
                        value: $event.currentTarget.innerHTML,
                        key: item.buttonText
                      })
                    "
                    v-html="getTranslations(item.buttonText)"
                  ></span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Button } from 'element-ui';
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';

export default {
  extends: BaseContentComponent,
  components: {
    elButton: Button
  },
  props: {},
  computed: {
    ...mapGetters('dashboard', ['dashboardData'])
  },
  mounted() {
    this.days = this.dashboardData.calendarDays;
    this.updateBarColor();

    if (this.editMode) {
      mediumEditor(
        `[id="${this.content.type}-${this.content.id}"] .content .titles h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.content.type}-${this.content.id}"] .content .titles h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      this.content.items.forEach((item, index) => {
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${index + 1}) h4`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${index + 1}) h5`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
        mediumEditor(
          `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${index +
            1}) .card-body > div`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
  },
  data() {
    return {
      days: 0
    };
  },
  methods: {
    setPlainText(event) {
      event.preventDefault();
      var text = event.clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
    },
    isActive(item) {
      let itemDays;

      if (this.days === 0) {
        return item.availableDays === 0 || item.availableDaysOption === 1;
      }

      // before so positive
      if (item.availableDaysOption === 1) {
        itemDays = item.availableDays;
      } else {
        itemDays = -item.availableDays;
      }

      // current workday before start
      if (this.days > 0) {
        return this.days <= itemDays;
      }

      // current workday after start
      if (this.days < 0) {
        return this.days < itemDays;
      }
    },

    contentSelected(i, content) {
      if (this.isSelectedItem(i, content).length > 0) {
        return { i, content };
      }
    },

    updateBarColor() {
      const id = `${this.content.type}-${this.content.id}`;
      const current = document.getElementById(id).querySelector('#timeline-styles');
      if (current) {
        current.parentNode.removeChild(current);
      }
      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'timeline-styles';
      style.appendChild(
        document.createTextNode(
          `
        #${id} .timeline-item.active .timeline-content .card-body:before,
        #${id} .timeline-item.active .timeline-card-left:after,
        #${id} .timeline-item.active .timeline-card-right:after,
        #${id} .timeline-item.active .timeline-card-left:before,
        #${id} .timeline-item.active .timeline-card-right:before {
          background-color: ${this.content.activeBarColor}
        }
        #${id} .timeline-item:not(.active) .timeline-content .card-body:before,
        #${id} .timeline-item:not(.active) .timeline-card-left:after,
        #${id} .timeline-item:not(.active) .timeline-card-right:after,
        #${id} .timeline-item:not(.active) .timeline-card-left:before,
        #${id} .timeline-item:not(.active) .timeline-card-right:before {
          background-color: ${this.content.barColor}
        }
    `
        )
      );
      const el = document
        .getElementById(`${this.content.type}-${this.content.id}`)
        .querySelector('.timeline-content-block');
      el.insertBefore(style, el.firstChild);
    },
    buttonStyle(item) {
      let style = {
        backgroundColor: item.buttonBackgroundColor,
        color: item.buttonColor
      };
      return style;
    },
    goToURL(item) {
      if (!this.editMode) {
        window.open(item.buttonUrl, '_blank');
      }
    },
    isInPreviewAdmin() {
      return (
        this.$route.name === 'EditorById' || this.$route.name === 'EditorByModuleId' || this.$route.name === 'Preview'
      );
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] .content .titles h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] .content .titles h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.items.length === oldContent.items.length) {
        newContent.items.forEach((e, i) => {
          if (e.visibilityElements.title && !oldContent.items[i].visibilityElements.title) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${i + 1}) h4`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
          if (e.visibilityElements.subtitle && !oldContent.items[i].visibilityElements.subtitle) {
            setTimeout(() => {
              mediumEditor(
                `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${i + 1}) h5`,
                ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
                this.predefinedColors
              );
            }, 300);
          }
        });
      }

      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new timeline component
        setTimeout(() => {
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${newContent.items.length}) h4`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${newContent.items.length}) h5`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.content.type}-${this.content.id}"] [id="timeline"] .timeline-item:nth-child(${newContent.items.length}) .card-body > div`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    },
    'content.barColor'(value) {
      Vue.nextTick(() => {
        this.updateBarColor();
      });
    },
    'content.activeBarColor'(value) {
      Vue.nextTick(() => {
        this.updateBarColor();
      });
    }
  }
};
</script>

<style lang="scss">
.screen.phone,
.screen.tablet {
  #timeline {
    .timeline-item {
      padding: 0 15px;

      .empty-card-left,
      .empty-card-right {
        display: none;
      }
      .timeline-card-right,
      .timeline-card-left {
        margin-top: 50px !important;
        padding: 0 !important;
        &:after {
          content: none;
        }
        &:before {
          bottom: 0;
          top: -25px;
          left: 27px;
          height: 50px;
          width: 3px;
        }
      }
      .timeline-card-left.first-without-image:before {
        top: -25px;
      }
    }
    .timeline-item:not(:first-of-type) {
      .timeline-card-right .margin-without-image,
      .timeline-card-left .margin-without-image {
        margin-top: 0px;
      }
    }

    .timeline-card-right .timeline-content .margin-without-image {
      margin-top: 0px;
    }
    .timeline-content .card-body:before {
      top: -22px !important;
      left: 14px !important;
      right: 0 !important;
      height: 28px !important;
      width: 28px !important;
      border: solid 6px white !important;
    }
  }
}

.screen.desktop {
  #timeline {
    .timeline-card-left.first-without-image:after {
      // height: calc(100% + 200px);

      @media (min-width: 991px) {
        top: 27px;
        height: calc(100% + 200px);
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

$timeline-item-margin-top: -150px;

@media (max-width: 991px) {
  #timeline .timeline-item {
    padding: 0 15px;

    .empty-card-left,
    .empty-card-right {
      display: none;
    }
    .timeline-card-right,
    .timeline-card-left {
      margin-top: 50px !important;
      padding: 0 !important;
      &:before {
        content: none;
      }
      &:after {
        bottom: 0;
        top: -25px;
        left: 27px;
        height: 50px;
        width: 3px;
      }
    }
    .timeline-card-right {
      &:after {
        left: 26px !important;
        z-index: -1;
      }
    }
    .timeline-card-left {
      &:after {
        left: 26px !important;
        z-index: -1;
      }
    }
  }
}

#app #timeline {
  .timeline-card-left.first-without-image:after {
    @media (min-width: 991px) {
      top: 27px;
      height: calc(100% + 200px);
    }
  }
}

#timeline {
  padding: 0 15px;
  .timeline-item:not(:first-of-type) {
    .timeline-card-right,
    .timeline-card-left {
      @media (max-width: 991px) {
        margin-top: 20px;
      }
      margin-top: $timeline-item-margin-top;
      .margin-without-image {
        margin-top: 250px;
        @media (max-width: 991px) {
          margin-top: 0px;
        }
      }
    }
  }
  .timeline-item:first-of-type {
    .timeline-card-right,
    .timeline-card-left {
      margin-top: 0 !important;
    }
    .timeline-card-left {
      .margin-without-image {
        .card-body:before {
          top: 10px;
        }
      }
    }
  }
  .timeline-item:last-of-type {
    .timeline-card-right,
    .timeline-card-left {
      &:after {
        height: 0;
      }
    }
  }

  .timeline-item {
    padding: 0;
    position: relative;
    margin-bottom: 0;

    .timeline-card-right {
      position: relative;
      z-index: 0;
      padding-left: 120px;
    }

    .timeline-card-left {
      position: relative;
      z-index: 0;
      padding-right: 120px;
    }

    .timeline-card-left:before,
    .timeline-card-left:after,
    .timeline-card-right:after {
      position: absolute;
      content: '';
      display: block;
      clear: both;
    }
    .timeline-card-right:before {
      position: absolute;
      content: '';
      display: block;
      clear: both;
    }

    .timeline-card-left.first-without-image:before {
      top: 30px;
    }

    .timeline-card-left:before,
    .timeline-card-right:before {
      top: 274px;
      width: 16%;
      height: 3px;
      z-index: -1;
      transform: translateY(-50%);

      @media (max-width: 991px) {
        display: none;
      }
    }

    .timeline-card-right:before {
      left: 0px;
    }
    .timeline-card-left:before {
      left: auto;
      right: -3px;
    }

    .timeline-card-left:after,
    .timeline-card-right:after {
      top: 277px;
      width: 3px;

      @media (max-width: 991px) {
        height: calc(100% - 140px);
      }

      @media (min-width: 991px) {
        height: calc(100% - 150px);
      }
    }

    .timeline-card-right.without-image::after,
    .timeline-card-left.without-image::after {
      @media (max-width: 991px) {
        top: 215px;
      }
    }
    .timeline-card-right:after {
      left: 0;
    }
    .timeline-card-left:after {
      left: auto;
      right: -3px;
    }

    .timeline-content.margin-without-image .card-body {
      border-top-left-radius: $general-border-radius;
      border-top-right-radius: $general-border-radius;
    }
    .timeline-content {
      border-radius: $general-border-radius;
      border: none;

      &.selected {
        box-shadow: 0 0 0 3px $color-blue;
      }

      .card-img-top {
        border-top-left-radius: $general-border-radius;
        border-top-right-radius: $general-border-radius;
        object-fit: cover;
        max-height: 100%;
      }

      .card-body {
        border-bottom-left-radius: $general-border-radius;
        border-bottom-right-radius: $general-border-radius;
        padding: 30px 25px 0 25px;
        border-top: none;

        h4 {
          font-size: 1.2rem;
          margin-bottom: 15px;
        }
        h5 {
          margin-bottom: 25px;
          font-weight: normal;
          font-size: 0.9rem;
        }
        .content-box {
          font-size: 0.9rem;
          margin-top: 0;
          margin-bottom: 30px;
        }
        button {
          border: none;
          margin-bottom: 25px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: black;
          top: 254px;
          border: solid 10px white;
          z-index: 1;

          @media (max-width: 991px) {
            top: -22px !important;
            left: 14px !important;
            right: 0 !important;
            height: 28px !important;
            width: 28px !important;
            border: solid 6px white !important;
          }
        }
      }
    }

    .card {
      position: unset !important; //This is set as the position:relative from card interferes with current styling
    }
    .timeline-card-left .timeline-content .card-body:before {
      right: -21px;
    }
    .timeline-card-right .timeline-content .card-body:before {
      left: -19px;
    }
  }
}

.col-4 {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-7 {
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
