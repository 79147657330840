<template>
  <div class="content-block-accordion" :id="accodeonId">
    <div class="content row" :class="{ editable: editMode }" @click="selectContentBlock()">
      <div class="headers" :class="accordionVariantStyle()">
        <h1
          v-html="getTranslations(content.title)"
          :class="titleClasses('title')"
          v-if="content.visibilityElements.title"
          :style="{ color: content.titleColor }"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('title')"
          @blur="handleBlur('[id=\'' + accodeonId + '\'] .headers h1', 'title', content.title, false, true)"
        ></h1>
        <h2
          v-html="getTranslations(content.subtitle)"
          v-if="content.visibilityElements.subtitle"
          :style="{ color: content.subtitleColor }"
          :class="titleClasses('subtitle')"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('subtitle')"
          @blur="handleBlur('[id=\'' + accodeonId + '\'] .headers h2', 'subtitle', content.subtitle, false, true)"
        ></h2>
      </div>
      <div class="accordion" :class="accordionVariantStyle()">
        <ul>
          <li
            v-for="(item, i) in content.items"
            :key="i"
            :class="isSelectedItem(i, content)"
            @click="setSelectedElement({ name: 'ac', field: 'text', id: i })"
          >
            <div
              class="collapsable"
              :class="{ open: item.isOpen }"
              :style="itemTitleStyle(item)"
              @click="onTitleClick(item, i), scrollToClick(i)"
              ref="collapsableDiv"
            >
              <span class="indicators" :style="itemTitleTextStyle(item)">
                <i class="material-icons" v-if="!item.isOpen">chevron_right</i>
                <i class="material-icons" v-else>expand_more</i>
              </span>
              <div
                v-html="getTranslations(item.title)"
                class="title"
                :style="itemTitleTextStyle(item)"
                :contenteditable="editMode"
                @blur="
                  removeBreaklines($event);
                  handleBlur(
                    '[id=\'' + accodeonId + '\'] .accordion ul li:nth-child(' + (i + 1) + ') .collapsable .title',
                    'title',
                    item.title,
                    false,
                    false,
                    item.id
                  );
                "
              ></div>
            </div>
            <div class="item item-content row" v-show="item.isOpen">
              <div
                v-html="getTranslations(item.text)"
                :class="itemTextClass(item)"
                :contenteditable="editMode"
                @blur="
                  handleBlur(
                    '[id=\'' + accodeonId + '\'] .accordion ul li:nth-child(' + (i + 1) + ') .item > div:first-child',
                    'text',
                    item.text,
                    false,
                    false,
                    item.id
                  )
                "
              ></div>
              <div :class="itemImageWrapper(item)" v-if="item.visibilityElements.image">
                <content-block-image
                  :editMode="editMode"
                  :title="getTranslations(item.title)"
                  :image="getImage(item.image)"
                  :carousel="getCarousel(item.carousel)"
                  :imageUrl="getTranslations(item.imageUrl)"
                  :imageClass="[...itemImageClass(item), ...accordionImageClass()]"
                ></content-block-image>
              </div>
              <div v-if="item.visibilityElements.video" :class="itemImageWrapper(item)">
                <video-block
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe" :class="itemImageWrapper(item)">
                <iframe-block :source="item.iframe"></iframe-block>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import BaseContentComponent from './BaseContentComponent.vue';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import IframeBlock from '@lp/components/content-blocks/IframeBlock.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  extends: BaseContentComponent,
  components: {
    VideoBlock,
    IframeBlock
  },
  created() {
    if (this.editMode) {
      this.content.items.forEach((item) => {
        item.isOpen = true;
      });
    } else {
      this.$store.commit('learningPages/setItemInitialStatus', {
        content: this.content
      });
    }
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.accodeonId}"] .headers h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.accodeonId}"] .headers h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );

      this.content.items.forEach((item, index) => {
        mediumEditor(`[id="${this.accodeonId}"] .accordion ul li:nth-child(${index + 1}) .collapsable .title`, [
          'boldStyle',
          'italicStyle',
          'bulletList',
          'colorPicker',
          'anchorLink'
        ]);
        const edit = mediumEditor(
          `[id="${this.accodeonId}"] .accordion ul li:nth-child(${index + 1}) .item > div:first-child`,
          ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
          this.predefinedColors
        );
      });
    }
  },
  data() {
    return {
      accodeonId: uuid.v4()
    };
  },
  computed: {
    ...mapGetters('learningPages', ['selectedContentBlock'])
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.accodeonId}"] .headers h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.accodeonId}"] .headers h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.items.length > oldContent.items.length) {
        // Add editor to the new accordion componentç
        setTimeout(() => {
          mediumEditor(
            `[id="${this.accodeonId}"] .accordion ul li:nth-child(${newContent.items.length}) .collapsable .title`,
            ['boldStyle', 'italicStyle', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
          mediumEditor(
            `[id="${this.accodeonId}"] .accordion ul li:nth-child(${newContent.items.length}) .item > div:first-child`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    checkViewport(obj) {
      let bounding = obj.getBoundingClientRect();

      return bounding.top >= 0 && bounding.left >= 0;
    },
    async scrollToClick(props) {
      let listIndex = this.$refs.collapsableDiv[props];

      await this.$nextTick(() => {
        !this.checkViewport(listIndex)
          ? listIndex.scrollIntoView({
              behavior: 'smooth'
            })
          : 0;
      });
    },
    accordionImageClass() {
      return ['mt-3', 'mt-md-0', 'mt-lg-0', 'img-fluid'];
    },
    onTitleClick(item, id) {
      if (this.editMode) {
        this.setSelectedElement({ name: 'ac', field: 'title', id });
      } else {
        this.$store.commit('learningPages/setItemStatusToggle', {
          content: this.content,
          item: item
        });

        // todo: do this via a mutation as it triggers a vuex warning directly mutating the state.
        // this.content.items.forEach((i, index) => {
        //   i.isOpen = id === index && !i.isOpen;
        // });
      }
    },
    accordionVariantStyle() {
      if (this.content.accordionVariant === 0) {
        return 'col-12';
      }
      return 'col-6';
    },
    itemTitleStyle(item) {
      return {
        'background-color': item.isOpen ? this.content.activeBackgroundColor : this.content.inactiveBackgroundColor
      };
    },
    itemTitleTextStyle(item) {
      return {
        color: item.isOpen ? this.content.activeTitleColor : this.content.inactiveTitleColor
      };
    },
    itemTextClass(item) {
      let classes = [item.alignment];
      switch (item.imagePos) {
        case 'left':
          classes.push('order-2');
          break;
        case 'center': {
          classes.push('col-12');
        }
      }

      if (
        item.imagePos !== 'center' &&
        (item.visibilityElements.image || item.visibilityElements.iframe || item.visibilityElements.video)
      ) {
        classes.push('col-md-6');
      }
      return classes;
    },
    itemImageWrapper(item) {
      let classes = [];
      switch (item.imagePos) {
        case 'left':
          classes.push('col-md-6');
          classes.push('text-left');
          break;
        case 'right':
          classes.push('col-md-6');
          classes.push('text-right');
          break;
        case 'center':
          classes.push('col-12');
          classes.push('text-center');
          break;
      }
      return classes;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

ul {
  padding-left: 0;

  li {
    list-style: none;
    margin-bottom: 10px;
    .collapsable {
      border: 1px solid $color-lightgrey;
      border-radius: $general-border-radius;
      cursor: pointer;
      padding: 10px;
      &.open {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: $general-border-radius;
        border-top-right-radius: $general-border-radius;
      }
    }
    i {
      vertical-align: middle;
    }
  }
}

.item {
  border-bottom-left-radius: $general-border-radius;
  border-bottom-right-radius: $general-border-radius;
  border-bottom: 1px solid $color-lightgrey;
  border-left: 1px solid $color-lightgrey;
  border-right: 1px solid $color-lightgrey;
  overflow: hidden;
}
.content-block .row {
  margin: 0;
  padding: 10px;
}

.content-block .headers {
  margin-bottom: 0;
}
.item-content.row p {
  width: 100%;
}
img {
  padding: 0;
}
.collapsable {
  display: flex;
}
div[contenteditable='true']:empty {
  display: inline-block;
}
</style>
<style>
.content-block .collapsable .title p {
  margin-top: 0 !important;
}
</style>
