<template>
  <div class="content" :class="{ editable: editMode }" @click="selectContentBlock()">
    <h1
      v-html="getTranslations(content.title)"
      :class="titleClasses('title')"
      :style="{ color: content.titleColor }"
      v-if="content.visibilityElements.title"
      :contenteditable="editMode"
      @mousedown="setSelectedElement('title')"
      @blur="changeTitle({ element: 'title', value: $event.currentTarget.innerHTML, key: content.title })"
    ></h1>
    <h2
      v-html="getTranslations(content.subtitle)"
      :class="titleClasses('subtitle')"
      :style="{ color: content.subtitleColor }"
      v-if="content.visibilityElements.subtitle"
      :contenteditable="editMode"
      @mousedown="setSelectedElement('subtitle')"
      @blur="changeTitle({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: content.subtitle })"
    ></h2>

    <!-- VARIANT 1 DESKTOP -->
    <div class="d-none" :class="{ 'd-sm-none d-md-block': content.variant === 0 }" id="management">
      <div class="col-md-12">
        <div
          v-for="(item, i) in content.items"
          :key="i"
          class="row"
          @click="setSelectedElement({ name: 'management', field: 'item', id: i })"
          :class="isSelectedItem(i, content)"
        >
          <div class="col-md-6 col-sm-12 photo" :class="itemClass(i, 0)">
            <content-block-image
              v-if="item.visibilityElements.image"
              :editMode="editMode"
              :image="getImage(item.image)"
              :carousel="getCarousel(item.carousel)"
              :imageUrl="getTranslations(item.imageUrl)"
              :imageClass="['img-fluid']"
            ></content-block-image>
            <div v-if="item.visibilityElements.video">
              <video-block
                :pageId="pageId"
                :content="content"
                :editMode="editMode"
                :url="getTranslations(item.video)"
              ></video-block>
            </div>

            <div v-if="item.visibilityElements.iframe">
              <iframe-block :source="item.iframe"></iframe-block>
            </div>
          </div>
          <div class="col-sm-12 info" :class="itemClass(i, 1, item)">
            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              @blur="changeItems({ element: 'title', value: $event.currentTarget.innerHTML, key: item.title })"
              :style="{ color: item.titleColor }"
              v-if="item.visibilityElements.title"
            ></h3>

            <h6
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              @blur="changeItems({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: item.subtitle })"
              :style="{ color: item.subtitleColor }"
              v-if="item.visibilityElements.subtitle"
            ></h6>

            <p
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              :style="{ color: item.textColor }"
              @blur="changeItems({ element: 'text', value: $event.currentTarget.innerHTML, key: item.text })"
            ></p>
            <a
              v-for="(value, key) in contact"
              :href="contactHref(key, item.contact[key])"
              :key="key"
              target="_blank"
              :title="key"
              class="contact"
              :style="{ color: item.titleColor }"
            >
              <i v-if="item.contact[key]" :class="value"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- VARIANT 1 MOBILE -->
    <div
      class="d-sm-block d-md-none"
      v-if="!this.content.visibilityElements.swipeableOnMobile && content.variant === 0"
    >
      <div class="row columns">
        <div
          v-for="(item, i) in content.items"
          :class="[{ 'mb-4': content.items.length > 4 }, columnsClass(i)]"
          :key="i"
          @click="setSelectedElement({ name: 'management', field: 'item', id: i })"
        >
          <div class="inner-col" :style="columnStyle(i)">
            <div style="text-align: center">
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :imageClass="['img-fluid']"
              ></content-block-image>
              <div v-if="item.visibilityElements.video">
                <video-block
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                  class="mb-3"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe">
                <iframe-block :source="item.iframe"></iframe-block>
              </div>
            </div>

            <h3
              :contenteditable="editMode"
              v-html="getTranslations(item.title)"
              @click="setSelectedElement({ name: 'management', field: 'title', id: i })"
              @blur="changeItems({ element: 'title', value: $event.currentTarget.innerHTML, key: item.title })"
              :style="{ color: item.titleColor }"
              v-if="item.visibilityElements.title"
            ></h3>
            <h5
              :contenteditable="editMode"
              v-html="getTranslations(item.subtitle)"
              @click="setSelectedElement({ name: 'management', field: 'subtitle', id: i })"
              @blur="changeItems({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: item.subtitle })"
              :style="{ color: item.subtitleColor }"
              v-if="item.visibilityElements.subtitle"
            ></h5>
            <p
              :contenteditable="editMode"
              v-html="getTranslations(item.text)"
              :style="{ color: item.textColor }"
              @blur="changeItems({ element: 'text', value: $event.currentTarget.innerHTML, key: item.text })"
            ></p>
            <div class="social">
              <a
                v-for="(value, key) in contact"
                :href="contactHref(key, item.contact[key])"
                :key="key"
                target="_blank"
                :title="key"
                class="contact"
                :style="{ color: item.titleColor }"
              >
                <i v-if="item.contact[key]" :class="value"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SWIPE ON MOBILE -->
    <div :class="carouselClass()" v-if="this.content.visibilityElements.swipeableOnMobile">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, i) in content.items" :key="i">
          <div class="col-sm-12 photo">
            <div
              class="col-md-6 col-sm-12 photo"
              :class="itemClass(i, 0)"
              @click="setSelectedElement({ name: 'management', field: 'image', id: i })"
            >
              <content-block-image
                v-if="item.visibilityElements.image"
                :editMode="editMode"
                :image="getImage(item.image)"
                :carousel="getCarousel(item.carousel)"
                :imageUrl="getTranslations(item.imageUrl)"
                :imageClass="['img-fluid']"
              ></content-block-image>

              <div v-if="item.visibilityElements.video">
                <video-block
                  :pageId="pageId"
                  :content="content"
                  :editMode="editMode"
                  :url="getTranslations(item.video)"
                ></video-block>
              </div>

              <div v-if="item.visibilityElements.iframe">
                <iframe-block :mapHeight="277" :source="item.iframe"></iframe-block>
              </div>
            </div>
            <div class="info">
              <h3
                :contenteditable="editMode"
                v-html="getTranslations(item.title)"
                @click="setSelectedElement({ name: 'management', field: 'title', id: i })"
                @blur="changeItems({ element: 'title', value: $event.currentTarget.innerHTML, key: item.title })"
                :style="{ color: item.titleColor }"
                v-if="item.visibilityElements.title"
              ></h3>

              <h6
                :contenteditable="editMode"
                v-html="getTranslations(item.subtitle)"
                @click="setSelectedElement({ name: 'management', field: 'subtitle', id: i })"
                @blur="changeItems({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: item.subtitle })"
                :style="{ color: item.subtitleColor }"
                v-if="item.visibilityElements.subtitle"
              ></h6>

              <p
                :contenteditable="editMode"
                v-html="getTranslations(item.text)"
                @click="setSelectedElement({ name: 'management', field: 'text', id: i })"
                :style="{ color: item.textColor }"
                @blur="changeItems({ element: 'text', value: $event.currentTarget.innerHTML, key: item.text })"
              ></p>
              <a
                v-for="(value, key) in contact"
                :href="contactHref(key, item.contact[key])"
                :key="key"
                target="_blank"
                :title="key"
                class="contact"
                :style="{ color: item.titleColor }"
              >
                <i v-if="item.contact[key]" :class="value"></i>
              </a>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <!-- VARIANT 2 DESKTOP -->
    <div class="d-none wrapperSwiper" :class="{ 'd-sm-none d-md-block': content.variant === 1 }">
      <div class="row horizontal-scrolling-wrapper pl-1 pr-1 pb-2 mx-0">
        <div
          v-for="(item, i) in content.items"
          :class="[{ 'mb-4': content.items.length > 4 }]"
          :key="i"
          @click="setSelectedElement({ name: 'management', field: 'item', id: i })"
        >
          <div class="inner-col" :style="columnStyle(i)">
            <div :style="getBackgroundImage(item)" class="bgImage">
              <div class="info">
                <h5
                  :contenteditable="editMode"
                  v-html="getTranslations(item.subtitle)"
                  @click="setSelectedElement({ name: 'management', field: 'subtitle', id: i })"
                  @blur="
                    changeItems({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: item.subtitle })
                  "
                  :style="{ color: item.subtitleColor }"
                  v-if="item.visibilityElements.subtitle"
                ></h5>
                <h3
                  :contenteditable="editMode"
                  v-html="getTranslations(item.title)"
                  @click="setSelectedElement({ name: 'management', field: 'title', id: i })"
                  @blur="changeItems({ element: 'title', value: $event.currentTarget.innerHTML, key: item.title })"
                  :style="{ color: item.titleColor }"
                  v-if="item.visibilityElements.title"
                ></h3>
                <div class="social">
                  <a
                    v-for="(value, key) in contact"
                    :href="contactHref(key, item.contact[key])"
                    :key="key"
                    target="_blank"
                    :title="key"
                    class="contact"
                    :style="{ color: item.titleColor }"
                  >
                    <i v-if="item.contact[key]" :class="value"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  VARIANT 2 MOBILE NON SWIPE -->
    <div
      class="d-sm-block d-md-none"
      v-if="!this.content.visibilityElements.swipeableOnMobile && content.variant === 1"
    >
      <div class="row horizontal-scrolling-wrapper pl-1 pr-1 pb-2 mx-0">
        <div
          v-for="(item, i) in content.items"
          :key="item.id"
          @click="setSelectedElement({ name: 'management', field: 'item', id: i })"
        >
          <div :style="[getBackgroundImage(item), columnStyle(i)]" class="bgImage">
            <div class="info">
              <h5
                :contenteditable="editMode"
                v-html="getTranslations(item.subtitle)"
                @click="setSelectedElement({ name: 'management', field: 'subtitle', id: i })"
                @blur="changeItems({ element: 'subtitle', value: $event.currentTarget.innerHTML, key: item.subtitle })"
                :style="{ color: item.subtitleColor }"
                v-if="item.visibilityElements.subtitle"
              ></h5>
              <h3
                :contenteditable="editMode"
                v-html="getTranslations(item.title)"
                @click="setSelectedElement({ name: 'management', field: 'title', id: i })"
                @blur="changeItems({ element: 'title', value: $event.currentTarget.innerHTML, key: item.title })"
                :style="{ color: item.titleColor }"
                v-if="item.visibilityElements.title"
              ></h3>

              <div class="social">
                <a
                  v-for="(value, key) in contact"
                  :href="contactHref(key, item.contact[key])"
                  :key="key"
                  target="_blank"
                  :title="key"
                  class="contact"
                  :style="{ color: item.titleColor }"
                >
                  <i v-if="item.contact[key]" :class="value"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from 'element-ui';
import BaseContentComponent from './BaseContentComponent.vue';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import IframeBlock from '@lp/components/content-blocks/IframeBlock.vue';
export default {
  extends: BaseContentComponent,
  components: {
    elButton: Button,
    VideoBlock,
    IframeBlock
  },
  props: {},
  data() {
    return {
      contact: {
        email: 'material-icons mail',
        phone: 'material-icons smartphone',
        facebook: 't-icon bold-53_logos-social_medias-social_media_facebook_1',
        linkedin: 't-icon bold-53_logos-social_medias-linkedin',
        twitter: 't-icon bold-53_logos-social_medias-twitter'
      },
      activeSlide: 0,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    };
  },
  methods: {
    getBackgroundImage(item) {
      let style = {};
      if (item.visibilityElements.image) {
        style['background-image'] = 'url("' + this.getImage(item.image) + '") ';
      }

      style['background-position'] = 'center center';
      style['background-size'] = 'auto 100%';
      style['background-repeat'] = 'no-repeat';

      return style;
    },
    columnStyle(id) {
      let style = {
        'background-color': this.content.items[id].backgroundColor
      };
      if (this.selectedContentBlock.id === this.content.id && this.selectedContentBlockElement.id == id) {
        style['outline'] = '3px solid #D9DDE0';
      }
      return style;
    },
    columnsClass(id) {
      let c = ['col-sm-12'];
      if (this.content.variant === 0) {
        switch (this.content.items.length) {
          case 1:
            c.push('col-md-12');
            break;
          case 2:
            c.push('col-md-6');
            break;
          case 3:
            c.push('col-md-4');
            break;
          default:
            c.push('col-md-3');
        }
      } else {
        c.push('col-md-4');
      }
      c.push(this.content.items[id].alignment);
      return c;
    },
    itemClass(index, col, item) {
      let classes = [];
      if (item) {
        if (item.visibilityElements.image || item.visibilityElements.video || item.visibilityElements.iframe) {
          classes.push('col-md-6');
        }
      }
      (index + col) % 2 ? classes.push('order-2') : classes.push('order-1');
      return classes;
    },
    carouselClass() {
      let c = ['d-md-none'];
      if (this.content.visibilityElements.swipeableOnMobile) {
        c.push('d-block', 'd-lg-none');
      } else {
        c.push('d-sm-none');
      }
      return c;
    },
    contactHref(k, v) {
      switch (k) {
        case 'email':
          return 'mailto:' + v;
          break;
        case 'phone':
          return 'tel:' + v;
          break;
        default:
          return v;
      }
    }
  }
};
</script>

<style lang="scss">
#management {
  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>

<style scoped lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

.swiper-pagination-bullets {
  top: 5px;
}

.wrapperSwiper {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.content {
  margin-bottom: 40px;
}
.photo {
  img {
    border-width: 0;
    width: 100%;
  }
  padding: 0;
}

.horizontal-scrolling-wrapper {
  overflow-y: hidden;
  .info {
    padding: 200px 10px 10px 10px;

    .social {
      margin-top: 1rem;
      margin-left: 0;
    }
  }

  .bgImage {
    height: 300px;
    width: 223px;
  }
}

.columns {
  .social {
    margin-top: 1rem;
  }
  .col-md-12,
  .col-md-6 {
    .bgImage {
      height: 400px;
    }

    .info {
      padding: 300px 10px 10px 10px;
    }
  }

  .col-md-4 {
    .bgImage {
      height: 300px;
      max-width: 223px;
    }

    .info {
      padding: 200px 10px 10px 10px;
    }
  }

  .col-md-3 .bgImage {
    height: 225px;
  }
}

#management {
  .screen.phone {
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-12 {
      padding: 0 5px;
    }
    .items-sm {
      display: block !important;
    }
    .items-md {
      display: none !important;
    }
    .info,
    .photo {
      order: 0;
    }
  }
  .screen.desktop,
  .screen.tablet {
    .col-sm-12 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .items-md {
      display: block !important;
    }
    .items-sm {
      display: none !important;
    }
    .col-md-12 {
      padding: 0 5px;
    }
  }
}
div.row {
  padding: 0;
}
.info {
  margin-top: 1.2rem;
}
div.row.selected {
  border: 2px solid $color-blue;
}
.contact {
  padding-bottom: 1em;
  display: inline-block;
  i {
    font-size: 1.3em;
    letter-spacing: 17px;
  }
}

.horizontal-scrolling-wrapper {
  img {
    height: 177px;
    object-fit: cover;
  }

  .inner-col {
    width: 223px;
  }
}

.swiper-slide {
  img {
    height: 277px;
    object-fit: cover;
    border-radius: $general-border-radius $general-border-radius 0 0;
  }
}

.screen.phone {
  .columns img {
    height: 282px;
  }
}

.columns {
  .col-md-12 img,
  .col-md-6 img {
    height: 282px;
  }

  .col-md-4 img {
    height: 184px;
  }

  .col-md-3 img {
    height: 128px;
  }

  .inner-col {
    border-radius: $general-border-radius;
    img {
      width: 100%;
      margin-bottom: 10px;
      border-radius: $general-border-radius $general-border-radius 0 0;
      object-fit: cover;
    }
    h3,
    h5,
    p {
      padding-left: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.social {
  margin-left: 10px;
}
</style>
