<template>
  <div class="content-block-header" :class="headerVariantClass()" :id="headerId">
    <div class="content" :class="{ editable: editMode }" :style="bgStyle()" @click="selectContentBlock()">
      <h1
        v-html="getTranslations(content.title)"
        :class="titleClasses('title')"
        :style="{ color: content.titleColor }"
        v-if="content.visibilityElements.title"
        :contenteditable="editMode"
        @mousedown="setSelectedElement('title')"
        @blur="handleBlur('[id=\'' + headerId + '\'] .content h1', 'title', content.title, false, true)"
      ></h1>

      <h2
        v-html="getTranslations(content.subtitle)"
        :class="titleClasses('subtitle')"
        :style="{ color: content.subtitleColor }"
        v-if="content.visibilityElements.subtitle"
        :contenteditable="editMode"
        @mousedown="setSelectedElement('subtitle')"
        @blur="handleBlur('[id=\'' + headerId + '\'] .content h2', 'subtitle', content.subtitle, false, true)"
      ></h2>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
export default {
  extends: BaseContentComponent,
  props: {},
  data() {
    return {
      headerId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      const verifyRegex = RegExp('color:.*?;', 'g');
      mediumEditor(
        `[id="${this.headerId}"] .content h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.headerId}"] .content h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.headerId}"] .content h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.headerId}"] .content h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    headerVariantClass() {
      return `variant-${this.content.headerVariant}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@shared/styles/_helpers.scss';

.content {
  border-radius: $general-border-radius;
}
.variant-0 {
  .content {
    padding: 79px;

    @media (max-width: 576px) {
      padding: 10px;
    }
  }
}
.variant-1 {
  .content {
    padding: 79px 0 79px 50px;
  }
}
</style>
