<template>
  <div>
    <div
      v-if="isRoundMe"
      class="youtube-embed-wrapper"
      style="height: 0px; overflow: hidden; padding-top: 30px; padding-bottom: 56.25%; position: relative"
    >
      <iframe
        allowfullscreen=""
        frameborder="0"
        height="360"
        :src="getThreeSixtySource"
        style="left: 0px; top: 0px; width: 100%; height: 90%; position: absolute"
        width="640"
      ></iframe>
    </div>

    <div
      v-if="isVrOptimal"
      class="vr-embed-wrapper"
      style="height: 0px; overflow: hidden; padding-top: 30px; padding-bottom: 56.25%; position: relative"
    >
      <iframe
        ado-inview="1"
        ado-ready="1"
        allowfullscreen="true"
        frameborder="0"
        height="360"
        id="ado-20"
        mozallowfullscreen="true"
        :src="getThreeSixtySource"
        style="left: 0px; top: 0px; width: 100%; height: 100%; position: absolute"
        webkitallowfullscreen="true"
        width="640"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: String
    },
    isRoundMe: {
      type: Boolean
    },
    isVrOptimal: {
      type: Boolean
    }
  },
  computed: {
    getThreeSixtySource() {
      if (!this.isValidThreeSixty) {
        return;
      }
      return this.source;
    },
    isValidThreeSixty() {
      if (!this.source) {
        return false;
      }

      return this.isRoundMe || this.isVrOptimal;
    }
  }
};
</script>
