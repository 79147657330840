<template></template>

<script>
import { mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
import ContentBlockImage from '@lp/components/content-blocks/ContentBlockImage.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    ContentBlockImage
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    },
    pageId: {
      required: true
    }
  },
  computed: {
    ...mapGetters('learningPages', [
      'companyImages',
      'getLearningPageTranslations',
      'selectedContentBlockElement',
      'selectedContentBlock',
      'getLearningPageById'
    ]),
    ...mapGetters('settings', ['predefinedColors'])
  },
  data() {
    return {
      firstSelectItem: false
    };
  },
  methods: {
    titleClasses(element) {
      let classes = [];
      if (this.selectedContentBlockElement && this.selectedContentBlockElement === element) {
        classes.push('selected');
      }

      classes.push(this.content.alignmentElements[element]);
      return classes;
    },

    isSelectedItem(id, content) {
      let classes = [];
      if (
        this.selectedContentBlockElement instanceof Object &&
        this.selectedContentBlockElement.id === id &&
        content.id === this.selectedContentBlock.id
      ) {
        classes.push('selected');
      }

      return classes;
    },
    backgroundImage(id) {
      return this.getImage(this.content.backgroundImage);
    },
    // Edit main titles:
    changeTitle(changeEvent) {
      const translation = this.getTranslations(this.content[changeEvent.element], true);
      if (translation.value === changeEvent.value) {
        return;
      }

      this.$store.commit('learningPages/editTranslationPage', {
        key: changeEvent.key,
        value: changeEvent.value,
        element: changeEvent.element
      });
    },
    selectContentBlock() {
      if (!this.firstSelectItem) {
        this.$emit('contentBlockElementSelected', 'content-block');
      }
      this.$emit('contentBlockSelected');
      this.firstSelectItem = false;
    },
    setSelectedElement(selectedElement) {
      this.firstSelectItem = true;
      this.$emit('contentBlockElementSelected', selectedElement);
    },
    // Select item
    changeItems(changeEvent) {
      const translation = this.getTranslations(changeEvent.key, true);
      if (translation.value === changeEvent.value) {
        return;
      }

      this.content.items.forEach((item) => {
        if (item[changeEvent.element] === changeEvent.key) {
          this.$store.commit('learningPages/editTranslationPage', {
            key: changeEvent.key,
            value: changeEvent.value,
            element: changeEvent.element,
            level: 'items'
          });
        }
      });
    },
    // Gets translations
    getTranslations(key, returnObj) {
      if (!this.pageId || !this.getLearningPageTranslations(this.pageId)) {
        return 'missing translation!!';
      }

      let ret = this.getLearningPageTranslations(this.pageId).translations.find(
        (translation) => translation.key === key
      );
      if (ret && ret.value && !returnObj) {
        return ret.value;
      } else if (ret && returnObj) {
        return ret;
      }
      return null;
    },
    getImage(key, long) {
      let translatedKey = this.getTranslations(key);

      let lp = this.getLearningPageById(this.pageId);

      if (!lp) {
        if (long) {
          return require('../../images/placeholderLong.png'); // default if not found
        }
        return require('@lp/images/placeholder.png'); // default if not found
      }

      let image = this.companyImages.find(
        (companyImage) => companyImage.key === translatedKey || companyImage.key.replace(/-/g, '') === translatedKey
      );

      if (!image) {
        image = lp.images.find((image) => {
          return image.key === translatedKey || image.key.replace(/-/g, '') === translatedKey;
        });
      }

      if (image) {
        return image.url;
      }
      if (long) {
        return require('../../images/placeholderLong.png'); // default if not found
      }
      return require('@lp/images/placeholder.png'); // default if not found
    },
    getCarousel(keys) {
      if (!keys) {
        return;
      }

      let lp = this.getLearningPageById(this.pageId);

      const images = keys.map((key) => {
        let translatedKey = this.getTranslations(key);
        let image = this.companyImages.find(
          (companyImage) => companyImage.key === translatedKey || companyImage.key.replace(/-/g, '') === translatedKey
        );

        if (!image) {
          image = lp.images.find((image) => {
            return image.key === translatedKey || image.key.replace(/-/g, '') === translatedKey;
          });
        }

        if (image) {
          return image.url;
        }
        return require('@lp/images/placeholder.png');
      });

      return images;
    },
    bgStyle() {
      let style = {};
      if (this.content.visibilityElements.backgroundColor) {
        style = { 'background-color': this.content.backgroundColor };
      }
      if (this.content.visibilityElements.backgroundImage) {
        style['background-image'] = 'url("' + this.getImage(this.content.backgroundImage) + '") ';
        style['background-position'] = 'center center';
        style['background-size'] = 'cover';
        style['background-repeat'] = 'no-repeat';
      }
      return style;
    },
    itemImageClass(item) {
      let ret = [];
      switch (item.imagePos) {
        case 'center':
          ret.push('col-12');
          break;
      }
      return ret;
    },
    setColorItem(color, id, key) {
      let newContent = cloneDeep(this.selectedContentBlock);
      newContent['items'][id][key] = color;
      this.$store.commit('learningPages/editContentBlock', newContent);
    },
    changeElementContentBlock(value, keyToChange) {
      let newContent = cloneDeep(this.selectedContentBlock);
      newContent[keyToChange] = value;
      this.$store.commit('learningPages/editContentBlock', newContent);
    },
    convertRgbToHex(value) {
      let colorRgb = value.match(/color:.*?;/g);
      let rgb = colorRgb[0].toString().replace(';', '');
      return `#${rgb
        .match(/\((.*)\)/)
        .pop()
        .split(',')
        .map((e) => ('0' + parseInt(e).toString(16)).slice(-2))
        .join('')}`;
    },
    removeColorAttributes(value) {
      if (value.match(/<span style="color:.*?;">.*?<\/span>/g)) {
        value = value.replace(/(<span style="color:.*?;">)(.*?)(<\/span>)/g, '$2');
      } else {
        value = value.replace(/color:.*?;/g, '');
      }
      return value;
    },
    handleBlur(query, element, key, removeColors, isTitle, elementId) {
      if (removeColors) {
        this.editComponent(query, element, key, removeColors, isTitle, elementId);
      }

      if (document.querySelector(query)) {
        let value = document.querySelector(query).innerHTML;
        if (value || value === '') {
          this.$store.commit('learningPages/editTranslationPage', {
            key: key,
            value: value,
            element: element
          });
        }
      }
    },
    editComponent(query, element, key, removeColors, isTitle, elementId) {
      let editorElement = document.querySelector(query).innerHTML;
      if (/color:.*?;/g.test(editorElement)) {
        let convertedRgb = this.convertRgbToHex(editorElement);
        editorElement = this.removeColorAttributes(editorElement);
        if (isTitle) {
          this.changeElementContentBlock(convertedRgb, element === 'title' ? 'titleColor' : 'subtitleColor');
        } else {
          this.setColorItem(convertedRgb, elementId, element === 'title' ? 'titleColor' : 'subtitleColor');
        }
        document.querySelector(query).innerHTML = editorElement;
      }
    },
    removeBreaklines(event) {
      event.target.innerHTML = event.target.innerHTML
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/<br>/g, '');
    }
  }
};
</script>
