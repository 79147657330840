<template>
  <div>
    <template v-if="isCarousel">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(image, i) in carousel" :key="i">
          <img :src="image" :alt="title" :class="imageClass" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </template>
    <template v-else>
      <template v-if="imageUrl && !editMode">
        <a :href="imageUrl" target="_blank"> <img :src="image" :alt="title" :class="imageClass"/></a>
      </template>
      <template v-else>
        <img :src="image" :alt="title" :class="imageClass" />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    carousel: {
      type: Array,
      default: () => []
    },
    imageUrl: {
      type: String
    },
    imageClass: {
      type: Array
    },
    editMode: {
      type: Boolean
    },
    title: {
      type: String
    }
  },
  computed: {
    isCarousel() {
      return this.carousel.length > 0;
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        observer: true,
        observeParents: true
      }
    };
  }
};
</script>

<style lang="scss">
.content {
  .swiper-container {
    z-index: 0;
  }

  .swiper-container .swiper-pagination .swiper-pagination-bullet {
    border-radius: 0px;
    height: 4px;
    width: 35px;
  }

  .swiper-pagination-bullets {
    left: 0;
    width: 100%;
    position: relative;
    top: 5px;
  }

  @media (max-width: 576px) {
    .swiper-pagination-bullets {
      top: 0px;
    }
  }

  ::v-deep .swiper-pagination-bullets {
    left: 0;
    width: 100%;
    position: relative;
    top: 5px;
  }

  ::v-deep .swiper-pagination-bullet {
    height: 4px;
    width: 35px;
  }

  @media (max-width: 576px) {
    ::v-deep .swiper-pagination-bullets {
      top: 0px;
    }
  }
}
</style>

<style scoped lang="scss">
a:hover {
  img {
    opacity: 0.9;
  }
}

img {
  width: 100%;
}
</style>
