<template>
  <div>
    <map-block v-if="isGoogleMapCode" :source="source" :mapHeight="mapHeight"></map-block>
    <three-sixty-block
      v-if="isValidThreeSixty"
      :isRoundMe="isRoundMe"
      :isVrOptimal="isVrOptimal"
      :source="source"
    ></three-sixty-block>
    <div v-if="!isGoogleMapCode && !isValidThreeSixty">
      <h2>{{ 'lp.invalidContent' | translate }}</h2>
    </div>
  </div>
</template>

<script>
import MapBlock from '@lp/components/content-blocks/MapBlock.vue';
import ThreeSixtyBlock from '@lp/components/content-blocks/ThreeSixtyBlock.vue';

export default {
  components: {
    MapBlock,
    ThreeSixtyBlock
  },
  props: {
    source: {
      type: String
    },
    mapHeight: {
      type: Number
    }
  },
  computed: {
    isGoogleMapCode() {
      if (!this.source) {
        return false;
      }
      return this.source.includes('www.google.com/maps');
    },
    isValidThreeSixty() {
      if (!this.source) {
        return false;
      }

      return this.isRoundMe || this.isVrOptimal;
    },
    isRoundMe() {
      if (!this.source) {
        return false;
      }

      return this.source.includes('roundme.com');
    },
    isVrOptimal() {
      if (!this.source) {
        return false;
      }

      return this.source.includes('vroptimal.com');
    }
  }
};
</script>
