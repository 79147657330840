<template>
  <div class="content-block-header" :class="statementVariantClass()" :id="statementId">
    <div class="content" :class="{ editable: editMode }" :style="bgStyle()" @click="$emit('contentBlockSelected')">
      <h1
        v-html="getTranslations(content.text)"
        :class="titleClasses('text')"
        v-if="content.visibilityElements.text"
        :contenteditable="editMode"
        :style="{ color: content.textColor }"
        @mousedown="setSelectedElement('text')"
        @blur="handleBlur('[id=\'' + statementId + '\'] .content h1', 'text', content.text, false, true)"
      ></h1>
      <h2
        v-html="getTranslations(content.signature)"
        :class="titleClasses('signature')"
        v-if="content.visibilityElements.signature"
        :contenteditable="editMode"
        :style="{ color: content.signatureColor }"
        @mousedown="setSelectedElement('signature')"
        @blur="handleBlur('[id=\'' + statementId + '\'] .content h2', 'signature', content.signature, false, true)"
      ></h2>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  extends: BaseContentComponent,
  props: {},
  data() {
    return {
      statementId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.statementId}"] .content h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.statementId}"] .content h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.signature && !oldContent.visibilityElements.signature) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.statementId}"] .content h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    statementVariantClass() {
      return `variant-${this.content.statementVariant}`;
    }
  }
};
</script>

<style scoped lang="scss">
.variant-0 {
  .content {
    padding: 59px;

    @media (max-width: 576px) {
      padding: 10px;
    }
  }
  .double-quotation {
    text-align: center;
  }
}
.variant-1 {
  .content {
    padding: 59px 50px;
  }
  .double-quotation {
    text-align: left;
  }
}
</style>
