<template>
  <div class="content-block-video" :id="videoId">
    <div class="content" :class="{ editable: editMode }" @click="selectContentBlock()">
      <div class="headers">
        <h1
          :style="{ color: content.titleColor }"
          v-html="getTranslations(content.title)"
          :class="titleClasses('title')"
          v-if="content.visibilityElements.title"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('title')"
          @blur="handleBlur('[id=\'' + videoId + '\'] .content .headers h1', 'title', content.title, false, true)"
        ></h1>
        <h2
          :style="{ color: content.subtitleColor }"
          v-html="getTranslations(content.subtitle)"
          :class="titleClasses('subtitle')"
          v-if="content.visibilityElements.subtitle"
          :contenteditable="editMode"
          @mousedown="setSelectedElement('subtitle')"
          @blur="handleBlur('[id=\'' + videoId + '\'] .content .headers h2', 'subtitle', content.subtitle, false, true)"
        ></h2>
      </div>
      <video-block
        :pageId="pageId"
        :content="content"
        :editMode="editMode"
        :url="getTranslations(content.url)"
      ></video-block>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import { mapGetters } from 'vuex';
import VideoBlock from '@lp/components/content-blocks/VideoBlock.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  extends: BaseContentComponent,
  components: {
    VideoBlock
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    }
  },
  data() {
    return {
      videoThumbnail: '',
      loadingThumbnail: false,
      previousUrl: null,
      videoId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.videoId}"] .content .headers h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.videoId}"] .content .headers h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.videoId}"] .content .headers h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.videoId}"] .content .headers h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  computed: {
    ...mapGetters('learningPages', ['learningPages'])
  }
};
</script>

<style scoped lang="scss"></style>
