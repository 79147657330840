<template>
  <div
    class="content-block"
    :class="{
      selected: contentBlock.id === selectedContentBlock.id,
      disabled: selectedContentBlockElement !== 'content-block'
    }"
  >
    <div class="content-block-label" @click="restoreSelectedContentBlockElement">
      {{ contentBlockLabel | translate }}
    </div>
    <component
      :is="getContentBlockType()"
      :content="contentBlock"
      :editMode="editMode"
      :pageId="this.pageId"
      @contentBlockSelected="setSelectedContentBlock()"
      @contentBlockElementSelected="setSelectedContentBlockElement($event)"
    ></component>
    <add-content-block-marker
      v-if="editMode"
      @markerClicked="$emit('addContentBlock', { id: contentBlock.id, position: 'after' })"
    ></add-content-block-marker>
  </div>
</template>
<script>
import AddContentBlockMarker from '@lp/components/AddContentBlockMarker.vue';
import ContentBlockImage from '@lp/components/content-blocks/ContentBlockImage.vue';
import AccordionContent from '@lp/components/content-blocks/AccordionContent.vue';
import VideoContent from '@lp/components/content-blocks/VideoContent.vue';
import HeaderContent from '@lp/components/content-blocks/HeaderContent.vue';
import ColumnsContent from '@lp/components/content-blocks/ColumnsContent.vue';
import TimelineContent from '@lp/components/content-blocks/TimelineContent.vue';
import TabsContent from '@lp/components/content-blocks/TabsContent.vue';
import StatementContent from '@lp/components/content-blocks/StatementContent.vue';
import ManagementContent from '@lp/components/content-blocks/ManagementContent.vue';
import SlalomContent from '@lp/components/content-blocks/SlalomContent.vue';
import ImageTextContent from '@lp/components/content-blocks/ImageTextContent.vue';
import TextContent from '@lp/components/content-blocks/TextContent.vue';
import MapContent from '@lp/components/content-blocks/MapContent.vue';
import ImageContent from '@lp/components/content-blocks/ImageContent.vue';
import FormContent from '@lp/components/content-blocks/FormContent.vue';
import SecureFilesContent from '@lp/components/content-blocks/SecureFilesContent.vue';
import KeyContactsContent from '@lp/components/content-blocks/KeyContactsContent.vue';

import { mapGetters } from 'vuex';
import { attachRedirectLinks } from '@/utils/attachRedirectLinks';

export default {
  components: {
    AddContentBlockMarker,
    AccordionContent,
    VideoContent,
    HeaderContent,
    ColumnsContent,
    TimelineContent,
    TabsContent,
    StatementContent,
    ManagementContent,
    ImageTextContent,
    TextContent,
    MapContent,
    ImageContent,
    SlalomContent,
    FormContent,
    ContentBlockImage,
    SecureFilesContent,
    KeyContactsContent
  },
  props: {
    contentBlock: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    },
    pageId: {
      required: true
    }
  },
  computed: {
    ...mapGetters('learningPages', ['selectedContentBlock', 'selectedContentBlockElement']),
    contentBlockLabel() {
      const result = this.contentBlock.displayName ? this.contentBlock.displayName : this.contentBlock.type;
      return result === 'Form' ? 'lp.componentForm' : result;
    }
  },
  mounted() {
    attachRedirectLinks(this.$el);
  },
  methods: {
    getContentBlockType() {
      return this.contentBlock.type + 'Content';
    },
    restoreSelectedContentBlockElement() {
      this.$store.commit('learningPages/setSelectedContentBlockElement', 'content-block');
    },
    setSelectedContentBlock() {
      if (this.editMode && this.selectedContentBlock.id !== this.contentBlock.id) {
        this.$store.commit('learningPages/setSelectedContentBlock', this.contentBlock);
      }
    },
    setSelectedContentBlockElement(selectedElement) {
      if (this.editMode && this.selectedContentBlockElement !== selectedElement) {
        this.$store.commit('learningPages/setSelectedContentBlockElement', selectedElement);
      }
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_colors.scss';
@import '@shared/styles/_helpers.scss';

body.visma {
  .content-block {
    &.selected {
      .content-block-label {
        font-family: 'Ubuntu', 'Open Sans', 'Helvetica Neue', 'Open Sans', 'Segoe UI', Roboto, Oxygen, sans-serif;
      }
    }
  }
}

[contenteditable='true'],
.editable {
  cursor: pointer;
}

[contenteditable='true']:hover,
.editable:hover {
  outline: 1px dashed #d9dde0;
}

.editor-page .content-block {
  margin-top: 24px;
}

.content-block {
  position: relative;

  .content-block-label {
    display: none;
    cursor: pointer;
  }

  .content {
    background: white;
  }

  &.selected {
    .content-block-label {
      font-family: Telegraf;
      display: block;
      position: relative;
      display: inline-block;
      border-top-left-radius: $general-border-radius;
      border-top-right-radius: $general-border-radius;
      background: #d9dde0;
      color: $color-darkgreyblue;
      font-weight: bold;
      padding: 6px 12px;
      margin-top: -15px;
      text-transform: uppercase;
      min-width: 120px;
      position: absolute;
      top: -17px;
      left: -3px;
    }

    .content {
      outline: 3px solid #d9dde0;
    }

    h1.selected,
    h2.selected,
    p.selected,
    div.selected {
      outline: 3px solid #d9dde0;
    }

    li.selected {
      border: 3px solid #d9dde0;
      border-radius: $general-border-radius;

      div {
        border: none;
        border-radius: 0;
      }
    }
  }

  &.selected.disabled {
    .content-block-label {
      background-color: #d9dde0;
    }

    .content {
      outline: 3px solid #d9dde0;
    }
  }

  a {
    display: inline-block !important;
  }
}
</style>
