<template>
  <div class="content image-content-block" :class="{ editable: editMode }" @click="selectContentBlock()" :id="imageId">
    <div class="headers">
      <h1
        v-html="getTranslations(content.title)"
        :class="titleClasses('title')"
        v-if="content.visibilityElements.title"
        :contenteditable="editMode"
        :style="{ color: content.titleColor }"
        @mousedown="setSelectedElement('title')"
        @blur="handleBlur('[id=\'' + imageId + '\'] h1', 'title', content.title, false, true)"
      ></h1>
      <h2
        v-html="getTranslations(content.subtitle)"
        :class="titleClasses('subtitle')"
        v-if="content.visibilityElements.subtitle"
        :contenteditable="editMode"
        :style="{ color: content.subtitleColor }"
        @mousedown="setSelectedElement('subtitle')"
        @blur="handleBlur('[id=\'' + imageId + '\'] h2', 'subtitle', content.subtitle, false, true)"
      ></h2>
    </div>
    <div>
      <!-- New image content with carousel support -->
      <template v-if="content.items">
        <content-block-image
          :editMode="editMode"
          :image="getImage(content.items[0].image || content.backgroundImage)"
          :carousel="getCarousel(content.items[0].carousel || [])"
          :imageUrl="getTranslations(content.items[0].imageUrl || content.imageUrl)"
          :title="getTranslations(content.title)"
          :imageClass="['mt-3', ' mt-md-0', 'mt-lg-0', 'img-fluid']"
        ></content-block-image>
      </template>
      <template v-else>
        <content-block-image
          :editMode="editMode"
          :image="getImage(content.image || content.backgroundImage)"
          :carousel="getCarousel(content.carousel)"
          :imageUrl="getTranslations(content.imageUrl)"
          :title="getTranslations(content.title)"
          :imageClass="['mt-3', ' mt-md-0', 'mt-lg-0', 'img-fluid']"
        ></content-block-image>
      </template>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
export default {
  extends: BaseContentComponent,
  data() {
    return {
      imageId: uuid.v4()
    };
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.imageId}"] h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.imageId}"] h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.imageId}"] h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.imageId}"] h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  }
};
</script>

<style lang="scss">
.image-content-block {
  img {
    width: 100%;
  }
}
</style>
