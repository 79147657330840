<template>
  <div class="content-block-header" :id="mapId">
    <div class="content" :class="{ editable: editMode }" @click="selectContentBlock()">
      <h1
        v-html="getTranslations(content.title)"
        :class="titleClasses('title')"
        v-if="content.visibilityElements.title"
        :contenteditable="editMode"
        :style="{ color: content.titleColor }"
        @mousedown="setSelectedElement('title')"
        @blur="handleBlur('[id=\'' + mapId + '\'] .content h1', 'title', content.title, false, true)"
      ></h1>
      <h2
        v-html="getTranslations(content.subtitle)"
        :class="titleClasses('subtitle')"
        v-if="content.visibilityElements.subtitle"
        :contenteditable="editMode"
        :style="{ color: content.subtitleColor }"
        @mousedown="setSelectedElement('subtitle')"
        @blur="handleBlur('[id=\'' + mapId + '\'] .content h2', 'subtitle', content.subtitle, false, true)"
      ></h2>
      <div v-if="consentForGoogleMaps || editMode" class="map" v-html="getMapCode(content.mapCode)" />
      <ask-consent-overlay v-if="!consentForGoogleMaps && !editMode" type="googleMaps"></ask-consent-overlay>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';
import { mapGetters } from 'vuex';
import AskConsentOverlay from '@/app/components/ui/consentOverlay/AskConsentOverlay.vue';

export default {
  extends: BaseContentComponent,
  props: {},
  name: 'mapcontent',
  components: { AskConsentOverlay },
  data() {
    return {
      mapId: uuid.v4()
    };
  },
  computed: {
    ...mapGetters('settings', ['consentForGoogleMaps'])
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(
        `[id="${this.mapId}"] .content h1`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
      mediumEditor(
        `[id="${this.mapId}"] .content h2`,
        ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
        this.predefinedColors
      );
    }
  },
  watch: {
    content: function(newContent, oldContent) {
      if (newContent.visibilityElements.title && !oldContent.visibilityElements.title) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.mapId}"] .content h1`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
      if (newContent.visibilityElements.subtitle && !oldContent.visibilityElements.subtitle) {
        setTimeout(() => {
          mediumEditor(
            `[id="${this.mapId}"] .content h2`,
            ['boldStyle', 'italicStyle', 'underline', 'bulletList', 'colorPicker', 'anchorLink'],
            this.predefinedColors
          );
        }, 300);
      }
    }
  },
  methods: {
    getMapCode() {
      let mapcode = this.content.mapCode
        .replace(/width="\d*"/gi, 'width="100%"')
        .replace(/height="\d*"/gi, 'height="500"');
      return mapcode;
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  text-align: center;
  .map {
    width: 100%;
  }
}
</style>
